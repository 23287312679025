import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const BecomeAPartner = () => {
  return (
    <section className='become-a-partner-section'>
      <div className='d-block d-md-flex justify-content-center py-4'>
        <div className='home-become-item text-center mx-3 mb-3'>
          <Image layout='responsive' width='500' height='334' src='/layout/become-valet' alt='Become a Valet' />

          <div className='py-4'>
            <h4>Become a Valet</h4>
          </div>

          <p className='px-5 pb-1'>Work around your schedule and earn Tips driving for ValetPress</p>

          <Link href='/partner-with-us/become-a-valet-driver/'>
            <a className='lezada-button lezada-button--medium my-2'>Learn More</a>
          </Link>

          <div className='pb-3'>
            <hr className='ms-auto me-auto' />
          </div>
        </div>
        <div className='home-become-item text-center mx-3 mb-3'>
          <div>
            <Image layout='responsive' width='500' height='334' src='/layout/for-cleaners' alt='For Cleaners' />
          </div>
          <div className='py-4'>
            <h4>For Cleaners</h4>
          </div>

          <p className='px-5 pb-1'>ValetPress can bring you more Business and Convenience</p>

          <Link href='/partner-with-us/become-a-cleaner-partner/'>
            <a className='lezada-button lezada-button--medium my-2'>Learn More</a>
          </Link>

          <div className='pb-3'>
            <hr className='ms-auto me-auto' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BecomeAPartner;
