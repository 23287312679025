import React from 'react';

const HowItWorks = (props) => {
  const {setModalGetQuoteShow} = props;

  return (
    <section className='how-it-works-section'>
      <div className='d-block d-md-flex justify-content-center py-4'>
        <div className='home-how-it-works-item text-center mb-3'>
          <div className='item-icon'>
            <img src={'/assets/images/home/create-an-account.svg'} alt='Create an account' />
          </div>

          <div className='py-3'>
            <h4>Create an Account</h4>
          </div>

          <hr className='ms-auto me-auto' />

          <p className='px-3 px-sm-5 pb-1'>Set your service & billing preferences, a Valet will be on their way.</p>
        </div>

        <div className='works-divider'>
          <hr className='ms-auto me-auto' />
        </div>

        <div className='home-how-it-works-item text-center mb-3'>
          <div className='item-icon'>
            <img src={'/assets/images/home/schedule-service.svg'} alt='Schedule Service' />
          </div>

          <div className='py-3'>
            <h4>Schedule Service</h4>
          </div>

          <hr className='ms-auto me-auto' />

          <p className='px-3 px-sm-5 pb-1'>Flexible on-demand or recurring pickup and delivery service.</p>
        </div>

        <div className='works-divider'>
          <hr className='ms-auto me-auto' />
        </div>

        <div className='home-how-it-works-item text-center mb-3'>
          <div className='item-icon'>
            <img src={'/assets/images/home/pickup-delivery.svg'} alt='Pickup & Delivery' />
          </div>

          <div className='py-3'>
            <h4>Pickup & Delivery</h4>
          </div>

          <hr className='ms-auto me-auto' />

          <p className='px-3 pb-1'>Picked up, handled, and delivered with exceptional customer service.</p>
        </div>
      </div>

      <div className='d-flex justify-content-center'>
        <button
          onClick={() => {
            setModalGetQuoteShow(true);
          }}
          className='lezada-button lezada-button--medium my-2'>
          Get A Quote
        </button>
      </div>
    </section>
  );
};

export default HowItWorks;
