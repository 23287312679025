import React, {useState} from 'react';
import {Container} from 'react-bootstrap';
import {LayoutTwo} from '../components/Layout';
import {FiMail} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {SectionTitleTwo} from '../components/SectionTitle';
import WhatWeOffer from '../components/shared/sections/WhatWeOffer';
import HowItWorks from '../components/shared/sections/HowItWorks';
import BecomeAPartner from '../components/shared/sections/BecomeAPartner';
import Link from 'next/link';
import {NextSeo} from 'next-seo';
import Image from 'next/image';
import dynamic from 'next/dynamic';

const DynamicCheckAvailabilityForm = dynamic(() => import('../components/forms/CheckAvailabilityForm'));
const DynamicGetQuoteForm = dynamic(() => import('../components/forms/GetQuoteForm'));

const Home = () => {
  const [modalCheckAvailabilityShow, setModalCheckAvailabilityShow] = useState(false);
  const [modalGetQuoteShow, setModalGetQuoteShow] = useState(false);

  return (
    <LayoutTwo>
      <NextSeo
        title='Procurement for Commercial Laundry &#8211; ValetPress, Inc.'
        description='ValetPress, Inc &#8211; Complete Business Garment Care. Delivered!'
        canonical={`${process.env.NEXT_PUBLIC_URL}/`}
      />
      <section className='home-top-section'>
        <Image
          layout='fill'
          src='/layout/woman-holding-stack-folded-clothes-isolated-min'
          alt='ValetPress, Inc'
          objectFit='cover'
          quality={70}
          placeholder='blur'
          // https://png-pixel.com/
          blurDataURL='data:image/webp;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPccfFtPQAHtgL3U+fmUgAAAABJRU5ErkJggg=='
        />

        <div className='home-background-overlay' />

        <div className='home-shape-bottom'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 100' preserveAspectRatio='none'>
            <path
              className='home-shape-fill'
              opacity='0.33'
              d='M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z'
            />
            <path
              className='home-shape-fill'
              opacity='0.66'
              d='M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z'
            />
            <path
              className='home-shape-fill'
              d='M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z'
            />
          </svg>
        </div>

        <Container className='home-container d-flex'>
          <div className='flex-row'>
            <h1 className='text-uppercase'>Procurement for Commercial Laundry</h1>

            <div className='d-flex justify-content-center mt-5'>
              <div className='top-text'>
                <p>
                  Your Reliable Best-Quality Service for All of Your Business Laundry, Dry Cleaning, Uniform & Work
                  Apparel Needs
                </p>
              </div>
            </div>

            <button
              onClick={() => {
                setModalCheckAvailabilityShow(true);
              }}
              className='lezada-button lezada-button--hover-white lezada-button--xl lezada-button--icon lezada-button--icon--right px-5 mt-5'>
              <FiMail className='mb-1' /> Check Availability
            </button>
          </div>
        </Container>
      </section>

      <section className='home-items-section'>
        <div className='d-block d-md-flex justify-content-center'>
          <div className='home-item d-flex flex-column'>
            <div className='d-flex justify-content-center'>
              <div className='item-icon'>
                <Link href='/markets/hospitality/'>
                  <a>
                    <img src={'/assets/images/home/hospitality.svg'} className='img-fluid' alt='Hospitality' />
                  </a>
                </Link>
              </div>
            </div>
            <div className='d-flex justify-content-center py-3'>
              <Link href='/markets/hospitality/'>
                <a>
                  <h3>Hospitality</h3>
                </a>
              </Link>
            </div>
            <div className='d-flex justify-content-center'>
              <p>Hotels, Resorts, Retirement Homes, Motels and Lodging</p>
            </div>
          </div>

          <div className='home-item d-flex flex-column'>
            <div className='d-flex justify-content-center'>
              <div className='item-icon'>
                <Link href='/markets/medical/'>
                  <a>
                    <img src={'/assets/images/home/medical.svg'} className='img-fluid' alt='Medical' />
                  </a>
                </Link>
              </div>
            </div>
            <div className='d-flex justify-content-center py-3'>
              <Link href='/markets/medical/'>
                <a>
                  <h3>Medical</h3>
                </a>
              </Link>
            </div>
            <div className='d-flex justify-content-center'>
              <p>Hospitals, Urgent Care, Dentists, Private Practices, Clinics Etc.</p>
            </div>
          </div>

          <div className='home-item d-flex flex-column'>
            <div className='d-flex justify-content-center'>
              <div className='item-icon'>
                <Link href='/markets/restaurants/'>
                  <a>
                    <img src={'/assets/images/home/restaurants.svg'} className='img-fluid' alt='Restaurants' />
                  </a>
                </Link>
              </div>
            </div>
            <div className='d-flex justify-content-center py-3'>
              <Link href='/markets/restaurants/'>
                <a>
                  <h3>Restaurants</h3>
                </a>
              </Link>
            </div>
            <div className='d-flex justify-content-center'>
              <p>Restaurants, Bars, Lounges, Country Clubs, Nightclubs, Etc.</p>
            </div>
          </div>

          <div className='home-item d-flex flex-column'>
            <div className='d-flex justify-content-center'>
              <div className='item-icon'>
                <Link href='/markets/industrial/'>
                  <a>
                    <img src={'/assets/images/home/uniforms.svg'} className='img-fluid' alt='Industrial' />
                  </a>
                </Link>
              </div>
            </div>
            <div className='d-flex justify-content-center py-3'>
              <Link href='/markets/industrial/'>
                <a>
                  <h3>Industrial</h3>
                </a>
              </Link>
            </div>
            <div className='d-flex justify-content-center'>
              <p>Automotive, Industrial, Drivers, Food Processing, Government, and More</p>
            </div>
          </div>

          <div className='home-item d-flex flex-column'>
            <div className='d-flex justify-content-center'>
              <div className='item-icon'>
                <Link href='/markets/linens/'>
                  <a>
                    <img src={'/assets/images/home/linens.svg'} className='img-fluid' alt='Linens' />
                  </a>
                </Link>
              </div>
            </div>
            <div className='d-flex justify-content-center py-3'>
              <Link href='/markets/linens/'>
                <a>
                  <h3>Linens</h3>
                </a>
              </Link>
            </div>
            <div className='d-flex justify-content-center'>
              <p>Towels, Bedding, Specialty Linens, Napkins, Tablecloths, Etc.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='home-content-section pt-5'>
        <Container>
          <SectionTitleTwo title='What We Offer' subtitle='Our Services' />
          <WhatWeOffer setModalCheckAvailabilityShow={setModalCheckAvailabilityShow} />

          <div className='py-5'>
            <h5 className='text-uppercase'>A Fresh New Service</h5>
            <h3 className='text-center py-4'>How It Works</h3>
            <HowItWorks setModalGetQuoteShow={setModalGetQuoteShow} />
          </div>

          <div className='d-block d-lg-flex py-4'>
            <div className='home-why-item mx-3 mb-3'>
              <h5 className='text-uppercase'>Better Service. Better Value. Better Quality.</h5>

              <h3 className='py-4'>Why Choose ValetPress</h3>

              <p>
                We make managed laundry service simple, convenient, and reliable for companies with uniform & laundry
                needs.
              </p>
              <p>
                Our mission is to enable any business with laundry service requirements to experience the same online
                ease as when they’re ordering food or scheduling a ride.
              </p>
              <p>
                Our customers can schedule, track, & manage their service from anywhere, anytime, with just a few
                clicks. It’s power at your fingertips.
              </p>

              <ul className='pt-3'>
                <li className='py-1'>
                  <BsCheck className='mb-1 list-icon' /> Pickup and Delivery On-Demand
                </li>
                <li className='py-1'>
                  <BsCheck className='mb-1 list-icon' /> Commercial Grade Cleaning
                </li>
                <li className='py-1'>
                  <BsCheck className='mb-1 list-icon' /> Friendly 24/7 customer service
                </li>
              </ul>
            </div>
            <div className='home-why-item mx-3 mb-3 text-end ps-5 pe-5 ps-sm-0 pe-sm-0 me-5'>
              <div className='why-image-1 text-center bg-info'>
                <Image layout='responsive' width='500' height='334' alt='Why Choose ValetPress' src='/layout/why-1' />
              </div>
              <div className='why-image-2 d-none d-lg-inline-block '>
                <Image
                  layout='responsive'
                  width='300'
                  height='187'
                  alt='Why Choose ValetPress, Inc'
                  src='/layout/why-2'
                />
              </div>
            </div>
          </div>

          <h5 className='pt-5 text-uppercase'>Let's Work Together</h5>
          <h3 className='text-center py-4'>Become a Partner</h3>
          <BecomeAPartner />
        </Container>

        {modalCheckAvailabilityShow && (
          <DynamicCheckAvailabilityForm
            show={modalCheckAvailabilityShow}
            onHide={() => setModalCheckAvailabilityShow(false)}
          />
        )}
        {modalGetQuoteShow && (
          <DynamicGetQuoteForm show={modalGetQuoteShow} onHide={() => setModalGetQuoteShow(false)} />
        )}
      </section>
    </LayoutTwo>
  );
};

export default Home;

export async function getServerSideProps({req}) {
  if (req.method !== 'GET') {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
      props: {},
    };
  }

  return {
    props: {},
  };
}
