import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const WhatWeOffer = (props) => {
  const {setModalCheckAvailabilityShow} = props;

  return (
    <div className='d-block d-md-flex justify-content-center pb-4 offer-section'>
      <div className='offer-item text-center mx-3 mb-3'>
        <div>
          <Image layout='responsive' width='380' height='252' src='/layout/offer-wash-and-fold' alt='Laundry Service' />
        </div>
        <div className='py-3'>
          <h4>Laundry Service</h4>
        </div>

        <p className='px-3 pb-1'>
          We’ll take the hassle out of caring for your everyday wash & wear garments. We Pickup and Deliver for your
          convenience
        </p>

        <button
          onClick={() => {
            setModalCheckAvailabilityShow(true);
          }}
          className='lezada-button lezada-button--outlined-secondary lezada-button--medium my-2'>
          Check Availability
        </button>

        <div className='pb-3'>
          <hr className='ms-auto me-auto' />
        </div>
      </div>
      <div className='offer-item text-center mx-3 mb-3'>
        <div>
          <Image layout='responsive' width='380' height='252' src='/layout/offer-dry-cleaning' alt='Dry Cleaning' />
        </div>
        <div className='py-3'>
          <h4>Dry Cleaning</h4>
        </div>

        <p className='px-3 pb-1'>
          When you need garments clean and pressed with a polished crisp look, then dry-cleaning is your ideal solution.
        </p>

        <button
          onClick={() => {
            setModalCheckAvailabilityShow(true);
          }}
          className='lezada-button lezada-button--outlined-secondary lezada-button--medium my-2'>
          Check Availability
        </button>

        <div className='pb-3'>
          <hr className='ms-auto me-auto' />
        </div>
      </div>
      <div className='offer-item text-center mx-3 mb-3'>
        <div>
          <Image
            layout='responsive'
            width='380'
            height='252'
            src='/layout/offer-business-apparel'
            alt='Business Apparel'
          />
        </div>
        <div className='py-3'>
          <h4>Business Apparel</h4>
        </div>

        <p className='px-3 pb-1'>
          The right business apparel sends the message to your customers that your company & team is professional
        </p>

        <Link href={process.env.NEXT_PUBLIC_SHOP_URL + '/'}>
          <a className='lezada-button lezada-button--outlined-secondary lezada-button--medium my-2'>Shop Now</a>
        </Link>

        <div className='pb-3'>
          <hr className='ms-auto me-auto' />
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
